import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import svLocale from "date-fns/locale/sv";
import React, { useEffect, useState } from "react";

/* eslint-disable */

export default function Slider(props) {
  const [selectedDate, setSelectedDate] = useState(new Date(props.date));
  const [date, setDateInt] = useState(new Date(props.date).getTime() / 1000);
  const [uniqueDates, setUniqueDates] = useState([]);

  const [counter, setCounter] = useState(0);
  useEffect(() => {
    // set current date to the first start_date in data. Uses the counter because of
    // the renders, we want to change the date on the last render.
    setCounter(counter + 1);
    if (
      props.data !== undefined &&
      counter === 2 &&
      props.data.features !== null
    ) {
      let startDate = new Date(props.data.features[0].properties.start_date);
      startDate.setDate(startDate.getDate());
      handleDateChange(startDate);
    }
  }, [props.data]);

  // Create a list with unique dates from the data. That later will be used to update map onClick
  useEffect(() => {
    sliderDates();
    function sliderDates() {
      if (props.data && props.data.features) {
        const d = props.data;
        let sliderData = [];

        for (let j = 0; j < d.features.length; j++) {
          let object = d.features[j].properties;
          let phase =
            d.features[j].properties.supervising +
            d.features[j].properties.phase_name;
          if (props.showLayer[phase] === true) {
            for (const property in object) {
              if (property === "start_date" && Date.parse(object[property])) {
                if (Date.parse(object[property])) {
                  sliderData.push(new Date(object[property]));
                }
              } else if (
                property === "stop_date" &&
                Date.parse(object[property])
              ) {
                let endDate = new Date(object[property]);
                endDate.setDate(endDate.getDate() + 1);
                sliderData.push(endDate);
              }
            }
          }
        }
        //https://stackoverflow.com/a/50735612
        let sortedDates = sliderData.sort((a, b) => a.getTime() - b.getTime());

        setUniqueDates(
          [...new Set(sortedDates.map((date) => +date))].map((d) => new Date(d))
        );
      }
    }
  }, [date]);

  function handle(e) {
    const date = new Date(e.target.value * 1000);
    const dateStr = date.toISOString().substr(0, 10);
    props.onChange(dateStr);
    setSelectedDate(date);
    setDateInt(e.target.value);
  }

  function handleDateChange(e) {
    setSelectedDate(e);
    setDateInt(e.getTime() / 1000);
    props.onChange(e.toISOString().substr(0, 10));
  }

  function nextDate(e) {
    // find next date if there is one.
    // https://stackoverflow.com/a/11796395
    var testDate = selectedDate,
      nextDateIndexesByDiff = [];

    for (var i = 0; i < uniqueDates.length; i++) {
      var thisDateStr = [uniqueDates[i]].join("/"),
        thisDate = new Date(thisDateStr),
        curDiff = testDate - thisDate;

      if (curDiff < 0) {
        nextDateIndexesByDiff.push([i, curDiff]);
      }
    }
    if (e.target.id === "next") {
      if (
        nextDateIndexesByDiff.length > 0 &&
        uniqueDates[nextDateIndexesByDiff[0][0]] !== undefined
      ) {
        handleDateChange(uniqueDates[nextDateIndexesByDiff[0][0]]);
      }
    }

    const [lastItem] = uniqueDates.slice(-1);

    if (e.target.id === "prev" && lastItem !== undefined) {
      if (
        nextDateIndexesByDiff.length < 1 &&
        selectedDate.toString() !== lastItem.toString()
      ) {
        handleDateChange(lastItem);
      } else if (selectedDate.toString() === lastItem.toString()) {
        let [nextToLastItem] = uniqueDates.slice(-2);
        handleDateChange(nextToLastItem);
      } else if (uniqueDates[nextDateIndexesByDiff[0][0] - 2] !== undefined) {
        // uniqueDates[nextDateIndexesByDiff[0][0] = next date (if it exist)
        // uniqueDates[nextDateIndexesByDiff[0][0] - 1 = current slider date
        // uniqueDates[nextDateIndexesByDiff[0][0] - 2 = previous date in the list.
        handleDateChange(uniqueDates[nextDateIndexesByDiff[0][0] - 2]);
      }
    }
  }

  return (
    <div
      className="navbar fixed-bottom navbar-south fade collapse show"
      id="navbarSouth"
    >
      <div className="container" id="navbarSouthContainer">
        <div className="row form-group">
          <label className="col-lg-2 col-form-label" htmlFor="input-range">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={svLocale}
            >
              <DatePicker
                className="input-group datepicker picker"
                value={selectedDate}
                onChange={handleDateChange}
                style="margin-bottom: 160px"
                format="do MMMM yyyy"
              />
            </LocalizationProvider>
          </label>

          <input
            className="col-gl-8 custom-range"
            id="input-range"
            type="range"
            value={date}
            min={
              uniqueDates.length > 0
                ? uniqueDates[0].getTime() / 1000
                : new Date("2022-01-01").getTime() / 1000
            }
            max={
              uniqueDates.length > 0
                ? uniqueDates[uniqueDates.length - 1].getTime() / 1000
                : new Date("2030-12-31").getTime() / 1000
            }
            // // max={2113772400}
            step={604800} // en vecka
            onChange={handle}
          />

          <div className="btn-group slider-btns">
            <button
              onClick={(e) => nextDate(e)}
              id="prev"
              className="btn btn-primary slider-btn"
              title="Tidigare händelse i karta"
              disabled={selectedDate <= new Date(Math.min(...uniqueDates))}
            >
              <i className="fa-light fa-chevron-left"></i>
            </button>

            <button
              onClick={(e) => nextDate(e)}
              id="next"
              className="btn btn-primary slider-btn"
              title="Nästa händelse i karta"
              disabled={selectedDate >= new Date(Math.max(...uniqueDates))}
            >
              <i className="fa-light fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
