import React from "react";
import Dropdown from "./Dropdown";

//button that toggles the menue on and off
export default function Header(props) {
  let headerTitle;
  if (props.headerTitle) {
    headerTitle = props.headerTitle;
  } else {
    headerTitle = "Skedesplan";
  }
  return (
    <nav
      className="navbar navbar-expand-lg fixed-top navbar-north"
      role="navigation"
      id="navbarNorth"
    >
      <div className="container" id="navbarNorthContainer">
        <div className="row no-gutters justify-space-between">
          <div>
            <header>
              <svg className="logotype logotype-white" />
            </header>
          </div>

          <div>
            <div className="collapse navbar-collapse" id="navbarNorthNav">
              <p className="headerContent">{headerTitle}</p>
            </div>
          </div>
          <div>
            <Dropdown
              user={props.user}
              userList={props.userList}
              hideDropdown={props.hideDropdown}
            />
          </div>
        </div>
      </div>
    </nav>
  );
}
